import {
  Button,
  Carousel,
  EButtonColorVariant,
  ETextStyleVariant,
  Text,
} from '@outdoorsyco/bonfire';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  DESTINATION_CARDS_SLIDES_CONFIG,
  EDestinationCardClassName,
  EThumbnailType,
  TDestinationCardThumbnail,
} from '@/constants/home/signatureDestinationsAndParks';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useHasBeenMounted } from '@/hooks/useHasBeenMounted';
import { useNationalParks } from '@/hooks/useNationalParks';

import { DestinationCard } from '../DestinationCard';
import { SectionHeading } from '../SectionHeading';
import css from './NationalParks.module.css';

const RENTAL_THUMBNAILS_LIMIT = 3;

export const NationalParks = () => {
  const intl = useIntl();
  const hasBeenMounted = useHasBeenMounted();
  const { isAboveDesktop, isAboveXXXL, isAboveXXXXL } = useBreakpoint();
  const desktopCarouselDescription = intl.formatMessage(
    {
      defaultMessage:
        'Cruise to epic locations in the comfort of an RV rental. From {pricePerNight}<Night>night</Night>',
      id: 'c48kdr',
    },
    {
      pricePerNight: <span className="font-bold">$45/</span>,
      Night: (text: React.ReactNode) => {
        return <span className="font-bold">{text}</span>;
      },
    },
  );

  let numberOfSlidesPerView = DESTINATION_CARDS_SLIDES_CONFIG.DEFAULT.PER_VIEW;
  let numberOfSlidesPerGroup = DESTINATION_CARDS_SLIDES_CONFIG.DEFAULT.PER_GROUP;
  if (isAboveXXXL) {
    numberOfSlidesPerView = DESTINATION_CARDS_SLIDES_CONFIG.XXXL.PER_VIEW;
    numberOfSlidesPerGroup = DESTINATION_CARDS_SLIDES_CONFIG.XXXL.PER_GROUP;
  }

  const { data } = useNationalParks();

  const nationalParks = useMemo(() => {
    if (!data) {
      const placeholderData = {
        name: '',
        imageSrc: '',
        imageSrcMobile: '',
        isLoading: true,
      };

      return Array.from({ length: 6 }, () => placeholderData);
    }

    if (data && (!data.parks || !data.parks.length)) {
      // very unlikely to happen, but in case no parks are returned don't show the section at all
      return [];
    }

    return data.parks.map(park => {
      const thumbnails: TDestinationCardThumbnail[] = [
        ...park.rentals.map(
          (rental): TDestinationCardThumbnail => ({
            type: EThumbnailType.ThumbnailImage,
            src: rental.primary_image_url,
            url: rental.slug,
          }),
        ),
      ];

      // add the text thumbnail if there are more than 3 rentals (unlikely to be an issue on prod)
      if (park.total_rentals > RENTAL_THUMBNAILS_LIMIT) {
        thumbnails.push({
          type: EThumbnailType.ThumbnailText,
          textContent: `+${park.total_rentals - RENTAL_THUMBNAILS_LIMIT}`,
          url: `/guide/${park.slug}`,
        });
      }

      return {
        name: park.name,
        locationName: park.location_name,
        url: `/guide/${park.slug}`,
        imageSrc: park.image_url,
        imageSrcMobile: park.mobile_image_url,
        thumbnails,
      };
    });
  }, [data]);

  if (!nationalParks.length) {
    return null;
  }

  return (
    <div className="px-6 mx-auto max-w-xxxxl lg:px-20 section-animated-block">
      <div className="pb-4 lg:pb-2">
        <SectionHeading
          text={intl.formatMessage({
            defaultMessage: 'Explore rentals near National Parks',
            id: 'MuZ0Vf',
          })}
        />
      </div>

      <Text className="pb-10 lg:hidden" variant={ETextStyleVariant.SmallRegular}>
        <FormattedMessage
          defaultMessage="From {pricePerNight}<Night>night</Night>"
          id="tjfy4x"
          values={{
            pricePerNight: <span className="font-bold">$45/</span>,
            Night: (text: React.ReactNode) => {
              return <span className="font-bold">{text}</span>;
            },
          }}
        />
      </Text>

      <div className="-mx-6 lg:hidden">
        <Swiper
          className={`${css.nationalParksSwiper}`}
          slidesPerView={1.2}
          centeredSlides={true}
          effect="coverflow"
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}>
          {nationalParks.map((destination, index) => (
            // Add transition effect only after the component has been mounted to avoid transition on initial render
            <SwiperSlide key={index} className={hasBeenMounted ? 'duration-[0.3s] ease-in' : ''}>
              <DestinationCard
                {...destination}
                imageSizes="85vw"
                isMobile={true}
                cardClassName={EDestinationCardClassName.NATIONAL_PARK}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className={`block ${css.nationalParksDesktopCarousel}`}>
        {isAboveDesktop && (
          <Carousel
            content={nationalParks.map((destination, index) => (
              <DestinationCard
                key={index}
                {...destination}
                imageSizes="35vw"
                isMobile={!isAboveXXXXL}
                cardClassName={EDestinationCardClassName.NATIONAL_PARK}
              />
            ))}
            description={desktopCarouselDescription as string}
            freeMode
            slidesPerView={numberOfSlidesPerView}
            slidesPerGroup={numberOfSlidesPerGroup}
            spaceBetween={16}
          />
        )}
      </div>

      <div className="flex content-center justify-center pt-10">
        <Button
          href="/guide/parks"
          label={intl.formatMessage({ defaultMessage: 'View all parks', id: '9knxaY' })}
          variant={EButtonColorVariant.Tertiary}
        />
      </div>
    </div>
  );
};
