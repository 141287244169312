import { Card, ETagVariant, GeneralCameraOffIcon, Loader, Tag } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import { clsx } from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Swiper } from 'swiper';

import SuperhostBadgeFullSvg from '@/assets/superhost/superhost-badge-full.svg';
import { FavoriteButton } from '@/components/common/FavoriteButton';
import ResponsiveImage from '@/components/utility/ResponsiveImage/ResponsiveImage';
import { WishlistModalWrapper } from '@/components/wishlists/WishlistModal';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { getWishlistsFeatureEnabled } from '@/redux/selectors/favorites';
import { IWishlistingEventData } from '@/services/analytics/listings/types';
import { trackEvent } from '@/services/track-event';
import { IRentalTag } from '@/services/types/core/reviews/id';
import { isProduction } from '@/utility/isSSR';
import { IRentalTile } from '@/utility/mapSearchResultToTile';

import css from './ModularListingTileMedia.module.css';

enum EListingTileTagSlug {
  Superhost = 'superhost',
  GuestFavorite = 'guest-favorite',
}

type TListingTileMediaProps = {
  rentalTile: IRentalTile;
  showFirstImageOnly?: boolean;
  addFavorite?: (id: number) => void;
  removeFavorite?: (id: number) => void;
  onSlideChange?: (nextIndex: number) => void;
  withPriority?: boolean;
  withFetchPriority?: 'high' | 'low' | undefined;
  largeSourceSize?: boolean;
  autoRotateOnHover?: boolean;
  autoRotateInterval?: number;
  className?: string;
  showSuperhost?: boolean;
  showGuestFavorite?: boolean;
  hideTagOnSmallScreen?: boolean;
  wishlistingEventData?: IWishlistingEventData;
  isOutlined?: boolean;
};

export const ModularListingTileMedia = ({
  rentalTile,
  showFirstImageOnly,
  addFavorite,
  removeFavorite,
  onSlideChange,
  withPriority = false,
  withFetchPriority,
  largeSourceSize = false,
  autoRotateOnHover = false,
  autoRotateInterval = 3000,
  className,
  showSuperhost = false,
  showGuestFavorite = false,
  hideTagOnSmallScreen = false,
  wishlistingEventData,
  isOutlined = false,
}: TListingTileMediaProps) => {
  const [swiperInstance, setSwiperInstance] = useState<Swiper>();
  const rotationTimeoutRef = useRef<number | null>(null);
  const { id = 0, loading, photoUrl, isFavorite, photoAlt, details, favoriteCount } = rentalTile;

  const { isAboveDesktop } = useBreakpoint();

  const isWishlistsFlagEnabled = useSelector(getWishlistsFeatureEnabled);

  const listingLoader = !!loading && (
    <Loader className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
  );

  const toggleFavorite =
    !!addFavorite &&
    !!removeFavorite &&
    (() => {
      trackEvent({
        event: 'rv-show/click',
        action: 'save',
        rentalId: id,
      });

      if (isFavorite) {
        removeFavorite(id);
        return;
      }

      addFavorite(id);
    });

  const tagElement = useMemo(() => {
    const superhostTag = details?.tags?.find(
      rentalTag => rentalTag.slug === EListingTileTagSlug.Superhost,
    );

    if (showSuperhost && superhostTag) {
      return <SuperhostBadgeFullSvg />;
    }

    const guestFavoriteTag = details?.tags?.find(
      rentalTag => rentalTag.slug === EListingTileTagSlug.GuestFavorite,
    );

    if (showGuestFavorite && guestFavoriteTag) {
      // the request for favorites returns tags in the IRentalTag format, without a tag prop
      const label = guestFavoriteTag.tag || (guestFavoriteTag as unknown as IRentalTag).name;
      return (
        <Tag
          className={cn('visible', { 'invisible sm:visible': hideTagOnSmallScreen })}
          variant={ETagVariant.White}
          label={label}
        />
      );
    }

    return null;
  }, [details, showSuperhost, showGuestFavorite, hideTagOnSmallScreen]);

  const favoriteToggleButton =
    !!toggleFavorite &&
    (!isWishlistsFlagEnabled ? (
      <FavoriteButton
        isFavorite={isFavorite}
        favoriteCount={favoriteCount}
        onClick={toggleFavorite}
      />
    ) : (
      <WishlistModalWrapper
        isFavorite={isFavorite}
        rental={rentalTile}
        wishlistingEventData={wishlistingEventData}
        TriggerComponent={({ isFavorite: isWishlisted, toggleFavorite: onToggleFavorite }) => (
          <FavoriteButton
            isFavorite={isWishlisted}
            favoriteCount={favoriteCount}
            onClick={onToggleFavorite}
          />
        )}
      />
    ));

  const actionsLayer = (
    <div
      className={clsx('absolute top-0 left-0 flex w-full p-2 gap-2 items-start', {
        'justify-between': !!tagElement,
        'justify-end': !tagElement,
      })}>
      {tagElement} {favoriteToggleButton}
    </div>
  );

  useEffect(() => {
    if (!swiperInstance) return;

    const handleRealIndexChange = (swiper: Swiper) => {
      onSlideChange?.(swiper.realIndex);
    };

    swiperInstance.on('realIndexChange', handleRealIndexChange);

    return () => {
      swiperInstance.off('realIndexChange', handleRealIndexChange);
    };
  }, [swiperInstance, onSlideChange]);

  const swiperInstanceRef = useRef<Swiper | undefined>(undefined);
  swiperInstanceRef.current = swiperInstance;

  // There might happen for the swiper instance to change before we register mouse enter event.
  // Work with a reference to the instance to make sure we always have the latest one.
  const handleMouseEnter = () => {
    if (!autoRotateOnHover) return;

    rotationTimeoutRef.current = window.setInterval(() => {
      swiperInstanceRef.current?.slideNext();
    }, autoRotateInterval);
  };

  const handleMouseLeave = () => {
    if (rotationTimeoutRef.current) {
      window.clearInterval(rotationTimeoutRef.current);
      rotationTimeoutRef.current = null;
    }
  };

  if (!loading && !showFirstImageOnly && Array.isArray(photoUrl) && photoUrl.length > 1) {
    return (
      <Card.Carousel
        ref={ref => setSwiperInstance(ref?.swiper)}
        fullRadius
        content={
          // Show a maximum of 7 images
          photoUrl.slice(0, 7).map((src, index) => (
            <MediaImage
              key={`${isProduction() ? 'image' : index}-${src}`}
              src={src}
              alt={index === 0 ? photoAlt : undefined}
              withPriority={index === 0 ? withPriority : undefined}
              withFetchPriority={index === 0 ? withFetchPriority : undefined}
              largeSourceSize={largeSourceSize}
              onMouseEnter={isAboveDesktop ? handleMouseEnter : undefined}
              onMouseLeave={isAboveDesktop ? handleMouseLeave : undefined}
            />
          ))
        }
        className={clsx('bg-gray-200', className, css.modularListingTileMediaCarousel, {
          'outline outline-2 outline-offset-2 outline-green-500': isOutlined,
        })}>
        {listingLoader}
        {actionsLayer}
      </Card.Carousel>
    );
  }

  return (
    <Card.Media
      fullRadius
      className={clsx('bg-gray-200', className, {
        'outline outline-2 outline-offset-2 outline-green-500': isOutlined,
      })}>
      {listingLoader}
      {typeof photoUrl === 'string' && (
        <MediaImage
          src={photoUrl}
          alt={photoAlt}
          withPriority={withPriority}
          withFetchPriority={withFetchPriority}
          largeSourceSize={largeSourceSize}
        />
      )}
      {Array.isArray(photoUrl) && !!photoUrl[0] && (
        <MediaImage
          src={photoUrl[0]}
          alt={photoAlt}
          withPriority={withPriority}
          withFetchPriority={withFetchPriority}
          largeSourceSize={largeSourceSize}
        />
      )}
      {actionsLayer}
    </Card.Media>
  );
};

const MediaImage = ({
  src,
  alt,
  withPriority = false,
  withFetchPriority,
  largeSourceSize,
  ...mouseEvents
}: {
  src: string;
  alt?: string;
  withPriority?: boolean;
  withFetchPriority?: 'high' | 'low' | undefined;
  largeSourceSize?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) => {
  return (
    <ResponsiveImage
      src={src}
      alt={alt}
      cropMode="fill"
      role="presentation"
      priority={withPriority}
      withFetchPriority={withFetchPriority}
      sourceSizes={largeSourceSize ? ['landscape572'] : ['landscape375']}
      className="object-cover object-center w-full h-full"
      {...mouseEvents}
      showFallbackComponent
      customFallbackContent={
        <div {...mouseEvents} className="flex items-center justify-center w-full h-full">
          <GeneralCameraOffIcon className="text-3xl" />
        </div>
      }
    />
  );
};
